@use 'src/styles/vars' as vars;
@use '~@hallmark/web.styles.colors/colors.module.scss' as colors;
@use '~@hallmark/web.styles.util/util.module.scss' as util;
@use '~@hallmark/web.styles.sizes/sizes.module.scss' as sizes;

.summary-drawer {
  z-index: vars.$IndexSidePanel;

  .summary-close-icon {
    padding-right: sizes.$space-24;
  }

  .header-wrapper {
    width: 80%;
    display: flex;
    justify-content: flex-start;
    padding-left: sizes.$space-24;
    gap: sizes.$space-16;
    color: colors.$primary-font-color;

    .draft-name-container {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .draft-name-form {
      display: flex;
      width: 100%;
      gap: sizes.$space-16;
      align-items: baseline;

      .summary-input {
        flex-grow: 1;
        width: 100%;
      }
    }
  }
}

.summary-grid {
  width: 100%;

  .summary-row {
    display: flex;
    padding: sizes.$space-8 sizes.$space-4;
    min-height: sizes.$space-56;
    border-bottom: util.$border-0;
    justify-content: space-between;
    align-items: center;

    .title-container {
      width: fit-content;
    }
  }

  .summary-row.summary-footer {
    border: none;
    justify-content: flex-end;

    .detail {
      flex-direction: column;
    }
  }

  .concept {
    flex-grow: 1;
  }

  .detail {
    text-align: right;
    margin-left: sizes.$space-24;
    padding: 0 0 0 sizes.$space-12;
  }

  .options-container {
    min-width: fit-content;
  }
}
