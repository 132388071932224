@use '~@hallmark/web.styles.colors/colors.module.scss' as colors;
@use '~@hallmark/web.styles.util/util.module.scss' as util;

// Contact Card Actions Styles
.address-book-address-actions {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: util.rem(16px);
  position: absolute;
  bottom: util.rem(12px);
  right: 0;
  z-index: 1;
  background-color: colors.$white;
  padding-left: 5px;
  padding-top: 5px;

  .icon {
    z-index: 1;

    :before {
      color: colors.$dark-gray;
    }
  }
}
