@use 'src/styles/vars' as vars;

.layout {
  width: 100vw;
  width: 100dvw;
  display: grid;
  height: 100vh;
  height: 100dvh;
  grid-template-columns: vars.$ToolBarWidth calc(100% - vars.$ToolBarWidth);
  grid-template-rows: vars.$PrintOnDemandHeader calc(100% - vars.$PrintOnDemandHeader);
  grid-template-areas:
    'toolbar header'
    'toolbar content';

  .content {
    grid-area: content;
  }

  &.no-toolbar {
    grid-template-columns: 100%;
    grid-template-rows: vars.$PrintOnDemandHeader calc(100% - vars.$PrintOnDemandHeader);
    grid-template-areas:
      'header'
      'content';
  }
}

.layout .btn {
  padding: 0 1.5rem;
}

@media (max-width: vars.$PhoneSize) {
  .layout:not(.no-toolbar) {
    grid-template-columns: 100%;
    grid-template-rows:
      vars.$PrintOnDemandHeaderMobile calc(100% - vars.$PrintOnDemandHeaderMobile - vars.$ToolBarHeightMobile)
      vars.$ToolBarHeightMobile;
    grid-template-areas:
      'header'
      'content'
      'toolbar';
  }
}
