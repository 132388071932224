@use '~@hallmark/web.styles.sizes/sizes.module.scss' as sizes;
@use '~@hallmark/web.styles.util/util.module.scss' as util;

.save-project-link {
  flex: 1;
  justify-content: flex-end;
  margin-right: 30px;
  /* Need this to override style in base-buttons.scss*/
  max-width: none;
  @include util.media(sizes.$mq-mobile) {
    width: auto;
    ~ .btn {
      margin-top: auto;
    }
  }

  &:disabled {
    span,
    span::before {
      color: var(--primary-button-disabled-color);
    }
  }
}

.save-project-empty-holder {
  display: flex;
  flex: 1;
}
