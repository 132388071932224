@use '~@hallmark/web.styles.util/util.module.scss' as util;
@use '~@hallmark/web.styles.sizes/sizes.module.scss' as sizes;
@use '~@hallmark/web.styles.colors/colors.module.scss' as colors;

@use 'src/styles/vars' as vars;

.toolbar {
  height: 100%;
  width: vars.$ToolBarWidth;
  z-index: vars.$IndexToolBar;
  background-color: var(--toolbar-background-color);
  padding: util.rem(60px) 0;
  grid-area: toolbar;

  .list {
    display: flex;
    flex-direction: column;
    height: 100%;

    li {
      display: contents;
    }

    .toolbar-button {
      transition: background-color 0.3s ease;
      flex-direction: column;
      row-gap: sizes.$space-4;
      width: 100%;
      padding: sizes.$space-12 0;
      border-radius: 0;
      overflow: hidden;

      .label {
        letter-spacing: 0.01em;
        font-size: sizes.$space-12;
        line-height: sizes.$space-16;
        display: block;
        padding: 0 sizes.$space-4;
        font-variation-settings: 'wght' 102, 'wdth' 800;
        color: var(--primary-button-text-color);
      }

      .tab {
        margin: auto;
      }

      &:hover,
      &:active {
        background-color: var(--primary-button-hover-background-color);
        span {
          color: var(--primary-button-hover-text-color);
          &:before {
            color: var(--primary-button-hover-text-color);
          }
        }
      }
    }
  }

  @media (max-width: vars.$PhoneSize) {
    width: 100%;
    height: vars.$ToolBarHeightMobile;
    padding: 0;

    .list {
      flex-direction: row;
    }

    .toolbar-button {
      flex-grow: 1;
      padding: sizes.$space-12;
    }
  }
}
