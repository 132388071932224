@use '~@hallmark/web.styles.colors/colors.module.scss' as colors;
@use '~@hallmark/web.styles.sizes/sizes.module.scss' as sizes;
@use '~@hallmark/web.styles.util/util.module.scss' as util;

.envelope-container {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-around;
  height: 100%;
  width: 100%;
  padding: sizes.$space-64;

  @media (sizes.$mq-tablet-p) {
    padding: 0;
    flex-direction: column;
  }
}

.envelope-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
  width: util.rem(410px);
  background-color: colors.$white;
  border-radius: sizes.$space-16;
  padding: util.rem(20px);
  gap: util.rem(20px);

  @media (sizes.$mq-tablet-p) {
    width: 100%;
    height: auto;
    position: relative;
  }

  .envelope-card-header {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    gap: 10px;

    h2 {
      color: colors.$dark-gray;
    }
  }

  .envelope-card-content {
    display: flex;
    flex-direction: column;
    gap: sizes.$space-16;
    width: 100%;

    .envelope-options {
      display: flex;
      flex-direction: column;
      gap: sizes.$space-8;

      > div > span {
        min-width: 20px;
      }
    }

    @media (sizes.$mq-tablet-p) {
      padding-bottom: 100px;
    }
  }

  .envelope-card-footer {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media (sizes.$mq-tablet-p) {
      position: absolute;
      display: flex;
      justify-content: space-between;
      align-items: center;
      bottom: 0;
      width: 100%;
      height: auto;
      padding: 0 sizes.$space-16;
      box-shadow: 0 -2px 10px 0 colors.$light-gray;
      border-top: 1px solid colors.$light-gray;

      button:first-of-type {
        // making sure the button is clickable in mobile
        padding: sizes.$space-16 sizes.$space-24;
      }

      button:last-of-type {
        margin: 1rem 0;
      }
    }
  }
}

@media (sizes.$mq-tablet-p) {
  .envelope-container {
    flex-direction: column;
  }
}
