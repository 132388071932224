@use 'src/styles/vars' as vars;
@use '~@hallmark/web.styles.colors/colors.module.scss' as colors;
@use '~@hallmark/web.styles.util/util.module.scss' as util;
@use '~@hallmark/web.styles.sizes/sizes.module.scss' as sizes;

.wam-instructions-drawer {
  z-index: vars.$IndexSidePanel;

  .drawer-header {
    color: colors.$dark-gray;
  }

  .upload-button {
    width: 100%;
    max-width: unset;
  }

  // only way to target the close icon and change it's color
  :global(.drawer-close) {
    span::before {
      color: colors.$black;
    }
  }

  .instruction-step {
    display: flex;
    justify-content: space-between;
    padding: sizes.$space-16 0;

    .instruction-image-wrapper {
      position: relative;
      flex: 0 0 65px;

      .instruction-image {
        height: auto;
        width: 100%;
        box-sizing: border-box;
        object-fit: contain;
      }

      .badge {
        position: absolute;
        width: sizes.$space-24;
        height: sizes.$space-24;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        top: -#{sizes.$space-12};
        right: -#{sizes.$space-12};
        border-radius: 50%;
        background-color: colors.$purple;
        color: colors.$white;
        font-size: util.rem(15px);
        line-height: util.rem(25px);
        background-color: var(--primary-button-background-color);
      }
    }

    .instruction-text {
      flex-grow: 1;
      padding-left: util.rem(28px);

      .step-title {
        color: colors.$medium-gray;
        line-height: util.rem(26px);
        display: block;
      }
    }
  }
}
