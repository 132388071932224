@use '~@hallmark/web.styles.sizes/sizes.module.scss' as sizes;
@use '~@hallmark/web.styles.colors/colors.module.scss' as colors;
@use '~@hallmark/web.styles.util/util.module.scss' as util;

.address-box {
  padding: sizes.$space-12 sizes.$space-12 sizes.$space-12;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  border: 1px solid colors.$medium-gray;
  margin: sizes.$space-16 0;
  text-align: center;
  position: relative;

  &.address-box-stepper {
    padding-top: sizes.$space-48;
  }

  .address-header {
    margin-bottom: sizes.$space-8;
    display: block;
  }

  .address {
    margin-bottom: sizes.$space-12;
    text-align: center;
  }

  .edit-button {
    width: 100%;
    display: block;
    max-width: unset;
    border-color: colors.$dark-gray;
    border-width: util.rem(1px);

    span {
      text-transform: unset;
    }
  }

  &:hover {
    cursor: pointer;
  }

  .stepper-label {
    background-color: colors.$light-gray;
    color: colors.$medium-gray;
    position: absolute;
    top: sizes.$space-12;
    right: sizes.$space-12;
  }
}
