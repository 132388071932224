@use '~@hallmark/web.styles.sizes/sizes.module.scss' as sizes;

.form-field {
  margin-top: sizes.$space-16;
  margin-bottom: 0 !important;
  width: 100%;

  &:first-of-type {
    margin-top: sizes.$space-8;
  }
}

.checkbox {
  margin-top: sizes.$space-16;
  margin-bottom: 0 !important;
}
