@use './vars.scss' as vars;

html {
  height: 100%;
}

body {
  display: flex;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  color: #2c3e50;
  background-color: vars.$PrimaryBackground;
}

a {
  text-decoration: none;
}

/*
svg {
  fill: currentColor;
  stroke: currentColor;
}
*/

button {
  img {
    vertical-align: middle;
  }
}

button,
input,
textarea,
select {
  color: inherit;
  font: inherit;
}
