@use '~@hallmark/web.styles.colors/colors.module.scss' as colors;
@use '~@hallmark/web.styles.util/util.module.scss' as util;
@use '../../styles/vars.scss' as vars;

.carousel-image-dot-container {
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: flex-start;
  font-family: inherit;
  padding: 0;

  @media (max-width: vars.$PhoneSize) {
    width: 100%;
    overflow: auto;
  }

  .active-image-dot-button {
    border: none;
    img {
      border: 3px solid var(--border-color);
    }
  }

  .image-dot-button {
    background: none;

    @media (max-width: vars.$PhoneSize) {
      margin: 0 !important;
    }

    div {
      color: colors.$dark-gray;
      font-size: util.rem(12px);
    }
    & span > div {
      margin-bottom: 10px;
      text-transform: capitalize;
    }
    img {
      width: auto;
      margin: auto;
    }

    &:last-of-type {
      margin: auto !important;
    }
  }
}
