@use './vars.scss' as vars;

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 11px;

  &.primaryButton {
    border-radius: 5px;
    background-color: vars.$HallMarkPurple;
    color: white;

    &.bPostButton {
      background-color: vars.$bPostRed;
    }
  }

  &.saveAndContinue {
    background-color: vars.$OrangeLion;

    &.bPostButton {
      background-color: vars.$bPostRed;
    }
  }

  &.saveAndContinue {
    border-radius: 5px;
    position: relative;
  }
}

input {
  box-shadow: none;
  padding: 0;
  margin: 0;
  border: 0;
  &:active,
  &:focus {
    outline: none;
    border: none;
  }
}

[data-type='modal'] {
  background: #fff;
  border-radius: 0.25rem;
  box-shadow: 0 0.625rem 0.625rem 0 rgb(0 0 0 / 18%);
  width: 100%;
  max-height: calc(100vh - 262px);
  max-width: 28rem;
  -webkit-transform: translateY(2rem);
  transform: translateY(2rem);
}
