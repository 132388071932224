@use '../../../styles/vars.scss' as vars;
@use '~@hallmark/web.styles.sizes/sizes.module.scss' as sizes;

.rotate-drawer {
  .header-wrapper {
    margin: 0 25%;
    text-align: center;

    @media (max-width: vars.$TabletSize) {
      margin: 0;
    }
  }

  .footer-wrapper {
    @extend .header-wrapper;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: sizes.$space-16 0;
  }

  .footer-buttons {
    margin: 5px;
    width: auto;
  }

  .rotation-label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 12px auto 24px auto;

    .value {
      &::after {
        content: '%';
      }
    }
  }
}
