@use '~@hallmark/web.styles.sizes/sizes.module.scss' as sizes;
@use '~@hallmark/web.styles.util/util.module.scss' as util;
@use '~@hallmark/web.styles.colors/colors.module.scss' as colors;

.quantity-link {
  flex: 1;
  justify-content: flex-start;
  /* Need this to override style in base-buttons.scss*/
  max-width: none;
  @include util.media(sizes.$mq-mobile) {
    width: auto;
    ~ .btn {
      margin-top: auto;
    }
  }
}

.quantity-input {
  flex: 1;
  justify-content: flex-start;
  margin: sizes.$space-16 0 0;
  border-radius: var(--input-border-radius);

  label[class*='field-label'] {
    position: relative;
    left: 0;
    margin-right: 4px;

    span[class*='field-label'] {
      background-color: transparent;
      color: var(--input-text-color);
    }
  }

  input {
    max-width: 72px;
    color: var(--input-text-color);
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
