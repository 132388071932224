@use '~@hallmark/web.styles.colors/colors.module.scss' as colors;
@use '~@hallmark/web.styles.util/util.module.scss' as util;
@use '~@hallmark/web.styles.sizes/sizes.module.scss' as sizes;

.dialogWrapper {
  div[class^='dialog_dialog-overlay'] {
    div[class^='dialog_dialog-wrapper'] {
      div[class^='dialog_dialog-content'] {
        padding: util.rem(20px);
      }

      div[class^='dialog_passive-footer'] {
        height: 0;
      }
    }
  }

  .container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .messageContainer {
      display: grid;
      gap: util.rem(10px);
      grid-template-rows: auto auto auto;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;
      margin-bottom: sizes.$space-32;

      span[class*='icon_icon-trash-bold'] {
        margin-top: sizes.$space-16;
        margin-bottom: util.rem(10px);

        &:before {
          color: colors.$dark-gray;
        }
      }

      > h4 {
        margin-bottom: 0;
      }
    }

    .buttonsContainer {
      display: flex;
      flex-direction: column;
      width: 100%;

      button {
        width: 100%;
        max-width: unset;

        &[class*='button_btn-secondary'] {
          margin-top: sizes.$space-16;
        }
      }
    }
  }
}
