@use '~@hallmark/web.styles.util/util.module.scss' as util;

.photo-drawer-container {
  position: absolute;
  left: 0;

  /* Header section */
  & > div:first-child {
    display: flex;
    flex-direction: row-reverse !important;
    padding: 0 util.rem(20px);
    margin-bottom: util.rem(12px) !important;
    margin-top: util.rem(8px) !important;

    h5 {
      margin-bottom: 0;
    }
  }

  /* Photo drawer body */
  & > :last-child {
    padding-bottom: util.rem(20px);

    & > div > div > div > div > [data-testid='photo-upload-button'] {
      height: util.rem(109px);
    }
  }

  /* Upload photo button */
  span {
    > span {
      font-size: util.rem(12px);
    }
  }
}
