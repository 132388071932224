@use '~@hallmark/web.styles.colors/colors.module.scss' as colors;
@use '~@hallmark/web.styles.util/util.module.scss' as util;
@use '~@hallmark/web.styles.sizes/sizes.module.scss' as sizes;

// hack to make the dialog rounded on the bottom
$rounded-bottom: 0 0 sizes.$space-16 sizes.$space-16;
.edit-address-dialog-wrapper {
  border-radius: $rounded-bottom;
  > div > div:last-child,
  > div > div:last-child > div {
    border-radius: $rounded-bottom;
  }
}

.edit-address-dialog {
  border-radius: sizes.$space-8;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;

  // hack to fix overlapping zip code field
  div[class*='dialog_dialog-content'] {
    padding-bottom: 7rem !important;
  }

  div[class*='edit-address-dialog_address-form-content'] {
    padding-top: sizes.$space-4 !important;
  }

  h2[class*='edit-address-dialog_title'] {
    text-align: center;
    margin-bottom: sizes.$space-8;
  }

  div[class*='dialog_footer-buttons'] {
    display: block !important;
    padding: sizes.$space-16 !important;
  }

  @include util.media(sizes.$mq-tablet-up) {
    max-width: util.rem(457px);
  }

  @include util.media(sizes.$mq-mobile-xl) {
    background-color: transparent;
  }

  .edit-address-form {
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .edit-address-form-content {
      padding: util.rem(20px);
      flex-grow: 1;

      @include util.media(sizes.$mq-mobile-xl) {
        flex-grow: 0;
        border-radius: sizes.$space-8;
      }
      .title {
        letter-spacing: 0;
        line-height: util.rem(26px);
        color: colors.$dark-gray;
      }
    }

    .form-field {
      margin: sizes.$space-16 0 0;
      width: 100%;
    }
  }
}
