@use '~@hallmark/web.styles.colors/colors.module.scss' as colors;
@use '~@hallmark/web.styles.util/util.module.scss' as util;
@use '~@hallmark/web.styles.sizes/sizes.module.scss' as sizes;

.font-drawer {
  z-index: 16;
  position: fixed;
  left: 0;

  & > div:last-of-type {
    @include util.media(sizes.$mq-tablet-l) {
      height: 350px;
    }
    & button {
      margin-top: 0;
    }
    & section {
      padding-bottom: 0;
    }
    & > div:last-of-type > div:last-of-type {
      margin-top: 0;
    }
  }

  div[class^='drawer_header'],
  div[class^='drawer_drawer-footer'] {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    button {
      margin: 0;
    }
  }

  div[class^='drawer_header'] {
    padding: 0 util.rem(20px);
    flex-flow: row-reverse wrap-reverse;
  }

  div[class^='tabs_tab-item'] {
    @include util.media(sizes.$mq-tablet-l) {
      button {
        span {
          &::before {
            color: colors.$charcoal-grey;
          }
        }
      }

      button[class*='tabs_tab-item-btn-selected'] {
        span {
          &::before {
            color: var(--icon-color);
          }
        }
      }
    }
  }

  .font-drawer-tabs > div:first-of-type {
    @include util.media(sizes.$mq-tablet-l) {
      position: absolute;
      top: -10%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: colors.$white;
      border-radius: sizes.$space-24;
      padding: sizes.$space-8 util.rem(20px);
      box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.18);
    }
  }

  .font-drawer-tabs > div:last-of-type {
    @include util.media(sizes.$mq-tablet-l) {
      width: 100%;
      margin: 0;
    }
  }

  .text-tab {
    .font-style {
      z-index: 30;
      width: 100%;
    }

    @include util.media(sizes.$mq-tablet-range) {
      display: flex;
      column-gap: util.rem(30px);
    }

    .counter-alignment-container {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .font-size-counter {
        padding: 0.75rem 0;
        & > span {
          width: util.rem(16px);
          text-align: center;
        }
        button {
          width: util.rem(48px);
          height: util.rem(48px);
          & > span::before {
            line-height: util.rem(24px);
            font-size: util.rem(24px);
          }
        }
      }

      .font-size {
        .font-size-counter {
          padding: 0.75rem 0;
        }

        & > span {
          width: util.rem(16px);
          text-align: center;
        }
        button {
          width: util.rem(48px);
          height: util.rem(48px);

          &:disabled {
            border: 2px solid #7f8386;
          }

          & > span::before {
            line-height: util.rem(24px);
            font-size: util.rem(24px);
          }
        }
      }

      .alignment {
        button {
          position: static !important;
          width: util.rem(48px);
          height: util.rem(48px);
          & > span::before {
            line-height: util.rem(24px);
            font-size: util.rem(24px);
          }
        }
      }
    }
  }

  .position-text-layer-label {
    margin-top: util.rem(16px);
  }

  .text-box-width {
    margin-top: util.rem(16px);
    .range-label {
      display: flex;
      justify-content: space-between;
      padding: 0 sizes.$space-8 sizes.$space-8;
    }

    .order-range {
      label {
        // Screen Reader Text
        clip: rect(1px, 1px, 1px, 1px);
        height: 1px;
        width: 1px;
        overflow: hidden;
        position: absolute;
      }
      & > div:first-of-type {
        height: util.rem(3px);
      }
    }
  }

  .color-option {
    padding: 0;
    margin-top: util.rem(16px);
    & > span {
      margin-bottom: sizes.$space-12;
      display: flex;
    }
    & > div {
      column-gap: util.rem(16px);
    }
    button {
      width: util.rem(52px);
      height: util.rem(52px);
    }
    button::before {
      line-height: util.rem(22px);
      width: util.rem(25px);
      height: util.rem(25px);
    }
    @include util.media(sizes.$mq-tablet-l) {
      & > div {
        column-gap: util.rem(8px);
      }
      button {
        width: util.rem(48px);
        height: util.rem(48px);
      }
    }
  }
}
