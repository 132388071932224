@use '~@hallmark/web.styles.util/util.module.scss' as util;
@use '~@hallmark/web.styles.sizes/sizes.module.scss' as sizes;
@use '../../../styles/vars.scss' as vars;

.options-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  overflow-x: auto;

  .button {
    &:first-child {
      margin-left: auto;
    }

    &:last-child {
      margin-right: auto;
    }

    @media (max-width: vars.$TabletSize) {
      margin: util.rem(5px);
    }
  }
}

.button {
  margin: util.rem(5px);
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: util.rem(63px);
  min-width: util.rem(58px);
  height: util.rem(72px);
}

.primary-button,
.secondary-button {
  margin-top: 1rem;
}

.icon {
  margin-bottom: util.rem(8px);
}

.submit-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  column-gap: sizes.$space-16;
}

.drawer-content {
  height: 1px;
  width: 1px;
  overflow: hidden;
  position: absolute;
}
