@use '~@hallmark/web.styles.util/util.module.scss' as util;
@use '~@hallmark/web.styles.sizes/sizes.module.scss' as sizes;
@use '~@hallmark/web.styles.colors/colors.module.scss' as colors;
@use 'src/styles/vars' as vars;

.preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: sizes.$space-16 sizes.$space-32;
  height: 100%;
  width: 100%;
  background-color: vars.$PrimaryBackground;
  overflow-y: hidden;

  @media (max-width: vars.$PhoneSize) {
    margin: 0;
    height: 100%;
    padding: 0;
  }

  .card-face-text {
    margin-left: 3em;
    margin-bottom: 1em;

    @media (max-width: vars.$PhoneSize) {
      margin-left: 1em;
      margin-top: 1em;
    }
  }

  .card-container {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    .prev-button,
    .next-button {
      display: flex;
      align-items: center;
      padding: 0 1rem;
      min-width: 4rem;
      box-sizing: border-box;
      .icon {
        display: flex;
        &:before {
          color: var(--icon-color);
        }
      }

      .button {
        border-color: var(--icon-color);
      }
    }

    .next-button {
      justify-content: end;
    }

    // Carousel
    .carousel-container {
      flex-grow: 1;

      .slide {
        background: none;
        display: flex;
        flex-direction: row-reverse;
        height: 100%;
        width: 100%;

        @media (max-width: vars.$PhoneSize) {
          justify-content: center;
        }
      }

      .preview-image {
        height: 100%;
        width: 100%;
        position: relative;
        margin: 0 auto;

        .image {
          height: 100%;
          object-fit: contain;
          position: absolute;
          inset: 0 auto 0;
        }
      }

      /** Use of :global isn't ideal but NukaCarousel doesn't provide a way to add a custom class to some of it's elements */
      :global(.slider-container) {
        width: 100%;
        height: 100%;
      }

      :global(.slider-frame),
      :global(.slider-list) {
        height: 100% !important; // Necessary to override the height: auto from the style attribute
      }

      :global(.slide) {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
      }
    }

    @media (max-width: vars.$PhoneSize) {
      flex-grow: 1;
      .prev-button,
      .next-button {
        display: none;
      }
    }
  }

  .play-button-mobile {
    display: none;
    @media (max-width: vars.$PhoneSize) {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 0 util.rem(15px) util.rem(15px);

      .animation-button-mobile {
        flex: 0 0 30%;
        margin: 0;
      }
    }
  }

  .navigation-buttons {
    display: flex;
    justify-content: space-between;
    position: sticky;
    bottom: 0;
    padding: sizes.$space-16 0;
    background-color: vars.$PrimaryBackground;

    @media (max-width: vars.$PhoneSize) {
      background-color: colors.$white;
      padding: sizes.$space-12 sizes.$space-16;
      box-shadow: 0 util.rem(-2px) util.rem(10px) 0 rgba(black, 0.25);
    }

    .edit-button {
      width: auto;
    }

    .action-buttons {
      display: flex;
      align-items: flex-end;

      .animation-button {
        @media (max-width: vars.$PhoneSize) {
          display: none;
        }
      }

      button {
        width: fit-content;
        margin-top: 0;

        &:not(:last-of-type) {
          margin-right: sizes.$space-16;
        }
      }
    }
  }

  .recipient-addresses-button {
    margin: 1rem auto;
    width: util.rem(241px);
    font-size: util.rem(14px);
    @media (max-width: vars.$PhoneSize) {
      margin-bottom: sizes.$space-16;
    }
  }
}

/** Delivery text component was removed from the design, but the code is still here in case it is needed in the future */
.delivery-text-mobile-container {
  flex: none;
  display: none;
  height: 100px;
  justify-content: center;
  align-items: center;
  background-color: vars.$HallMarkPurple;
  @media (max-width: vars.$PhoneSize) {
    display: flex;
  }
  .delivery-text-mobile {
    text-align: center;
    color: colors.$white;
  }
}

.delivery-text {
  margin-top: 50px;
  text-align: center;
  width: 35%;
  display: flex;
  flex-direction: column;
  gap: 4em;
  p {
    margin: 1em 0;
  }
  @media (max-width: vars.$PhoneSize) {
    width: 100%;
    margin-top: util.rem(14px);

    .delivery-text-title {
      font-size: util.rem(22px);
    }

    .delivery-text-body {
      position: absolute;
      left: 0;
      right: 0;
      display: none;
      margin: auto;
      z-index: 20;
      color: white;
    }

    .delivery-text-bottom {
      display: none;
    }
  }
}

.email-splash {
  animation: slideFromBottom 1s ease-out 0s 1 normal both;

  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  gap: sizes.$space-12;
  padding: sizes.$space-16;
  img {
    margin-bottom: sizes.$space-16;
  }

  .buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: sizes.$space-16;
  }

  @media (sizes.$mq-tablet-up) {
    .buttons {
      gap: sizes.$space-16;
      justify-content: center;
      flex-direction: row;
    }
  }
}

@keyframes slideFromBottom {
  0% {
    opacity: 0;
    transform: translateY(250px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.consumer-review-dialog {
  max-width: 31rem !important;

  h2 {
    font-size: 30px;
    font-family: 'Sincerely Regular', sans-serif;
  }

  p {
    font-weight: bold;
    font-family: 'BeamNewHMK-Bold', sans-serif;
    padding-right: 1.25rem;
  }
}

.consumer-description-container {
  margin-top: 48px;
  font-family: 'BeamNewHMK-Regular', sans-serif;
  display: inline-flex;
  padding-right: 1.25rem;

  input[type='checkbox'] {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    border-radius: 3px;
    position: relative;
    cursor: pointer;
    accent-color: var(--primary-button-background-color);
  }
}

@media (max-height: 600px) {
  .consumer-description-container {
    margin-top: 12px;
  }

  .consumer-review-dialog div:nth-child(3) {
    padding-top: 0;
  }

  .consumer-review-description {
    margin-top: -18px !important;
  }

  .consumer-review-dialog {
    h2 {
      font-size: 24px;
    }
  }
}
