@use '~@hallmark/web.styles.colors/colors.module.scss' as colors;
@use '~@hallmark/web.styles.util/util.module.scss' as util;

.bulk-upload-validation-dialog {
  align-items: center;
  justify-content: center;
  text-align: center;
}

.validation-dialog-title {
  margin-bottom: util.rem(16px);
}

.validation-dialog-header-content {
  margin-bottom: util.rem(24px);
}

.bulk-upload-validation-dialog-error-zone {
  border-radius: util.rem(16px);
  border: util.rem(1px) solid colors.$error;
  display: flex;
  flex-direction: column;
  padding: util.rem(8px) 0;
}

.error-zone-title {
  line-height: util.rem(24px);
}

.bulk-upload-validation-dialog-resolved-zone {
  border-radius: util.rem(16px);
  background-color: colors.$success-background;
  padding: util.rem(8px) 0;
}

.resolved-zone-title {
  line-height: util.rem(24px);
}

.bulk-upload-form-section {
  display: flex;
  flex-direction: column;
  text-align: left;
}
