@use '~@hallmark/web.styles.colors/colors.module.scss' as colors;
@use '~@hallmark/web.styles.util/util.module.scss' as util;

.contact-card {
  position: relative;

  // Radio Card Styles for Single Address Selection
  .radio-card input[type='radio'],
  .radio-card input[type='radio']:hover,
  .radio-card input[type='radio']:checked {
    border: none;
    border-bottom: 1px solid colors.$medium-gray;
    border-radius: 0;
  }

  .radio-card > span {
    align-self: flex-start;
  }

  .radio-card label {
    display: block;
  }

  div[class^='radio-card_input-field-container'] {
    flex-direction: row-reverse;
    padding: util.rem(12px) 0;

    label {
      padding-left: 0 !important;
      padding-right: 10px !important;
    }
  }

  // Checkbox Styles for Multiple Address Selection
  .checkbox input[type='checkbox'],
  .checkbox input[type='checkbox']:hover,
  .checkbox input[type='checkbox']:checked {
    border: none;
    border-bottom: 1px solid colors.$medium-gray;
    border-radius: 0;
  }

  .checkbox > span {
    align-self: flex-start;
  }

  .checkbox label {
    display: block;
  }

  div[class^='checkbox_input-field-container'] {
    flex-direction: row-reverse;
    justify-content: space-between;
    border-bottom: 1px solid colors.$medium-gray;
    padding: 0 0 util.rem(12px) 0;

    label {
      width: 100%;
      padding-left: 0 !important;
      padding-right: 10px !important;
    }
  }
}

// Checkbox Style for Multiple Address Selection
.multi-selection {
  margin: 1rem;

  div[class^='checkbox_input-field-container'] > div {
    width: 100%;
  }
}
