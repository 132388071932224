@use '~@hallmark/web.styles.colors/colors.module.scss' as colors;
@use '~@hallmark/web.styles.util/util.module.scss' as util;
@use '~@hallmark/web.styles.sizes/sizes.module.scss' as sizes;

.email-address-form-wrapper {
  background-color: colors.$white;
  border-radius: sizes.$space-8;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;

  @include util.media(sizes.$mq-tablet-up) {
    max-width: util.rem(457px);
  }

  @include util.media(sizes.$mq-mobile-xl) {
    background-color: transparent;
  }

  .email-address-form {
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .email-address-form-content {
      padding: util.rem(20px);
      flex-grow: 1;

      @include util.media(sizes.$mq-mobile-xl) {
        background-color: colors.$white;
        flex-grow: 0;
        border-radius: sizes.$space-8;
      }
    }

    .title {
      letter-spacing: 0;
      line-height: util.rem(26px);
      color: colors.$dark-gray;
    }

    .form-field {
      margin: sizes.$space-16 0 0;
      width: 100%;

      &:first-of-type {
        margin-top: sizes.$space-8;
      }
    }
  }
}
