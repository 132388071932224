@use '~@hallmark/web.styles.colors/colors.module.scss' as colors;
@use '~@hallmark/web.styles.sizes/sizes.module.scss' as sizes;
@use '~@hallmark/web.styles.util/util.module.scss' as util;

.envelope-preview {
  width: 100%;
  padding: 0 sizes.$space-32;
  height: auto;
  max-width: util.rem(735px);
  min-height: util.rem(510px);

  @media (sizes.$mq-tablet-p) {
    display: flex;
    align-items: center;
    padding: sizes.$space-16;
    height: fit-content;
    max-width: util.rem(715px);
    min-height: util.rem(510px);

    > div {
      height: fit-content;
    }
  }

  picture {
    width: 100%;

    > img {
      width: 100%;
      animation: envelopeSelection 350ms ease-in-out 0s 1 alternate forwards;
    }
  }
}

// animation for envelope preview
@keyframes envelopeSelection {
  0% {
    opacity: 0;
    transform: translateX(-250px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
