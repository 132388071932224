@use '~@hallmark/web.styles.sizes/sizes.module.scss' as sizes;
@use '~@hallmark/web.styles.util/util.module.scss' as util;
@use 'src/styles/vars' as vars;

.header {
  display: flex;
  flex-flow: row nowrap;
  height: vars.$PrintOnDemandHeader;
  background: transparent;
  justify-content: space-between;
  align-items: center;
  padding: util.rem(12px) util.rem(40px);
  grid-area: header;
  @include util.media(sizes.$mq-mobile) {
    background-color: white;
    padding: util.rem(12px) util.rem(12px);
    height: vars.$PrintOnDemandHeaderMobile;
  }

  .crown {
    width: auto;
    flex: 1;
    text-align: center;
    align-items: center;
    padding: 0;
    border: 0;
    /* Need this to override style in base-buttons.scss*/
    max-width: none;
    background: transparent;
    @include util.media(sizes.$mq-mobile) {
      margin-top: 0;

      span {
        svg {
          height: util.rem(32px);
          width: util.rem(32px);
        }
      }
    }

    span {
      height: util.rem(29px);
    }

    &:hover {
      box-shadow: none;
    }
  }
  .close-button {
    /* Need this to override style in base-buttons.scss*/
    display: flex;
    max-width: none;
    margin: 0;
    width: auto;
    :before {
      color: var(--icon-color);
    }

    &:hover {
      :before {
        color: var(--primary-button-hover-background-color);
      }
    }
  }

  .save-project {
    display: flex;
    flex: 1;
  }

  .quantity-link-placeholder {
    flex: 1;
  }
}
