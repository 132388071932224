//@use './styles/shared.scss';
@use './styles/vars.scss' as vars;
@use './styles/reset.scss';
@use './styles/forms.scss';

* {
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.app {
  background-color: var(--appBackground, #e4e2ed);
  background-repeat: repeat;
  height: 100%;
  max-width: 100vw;
}
