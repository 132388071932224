@use '~@hallmark/web.styles.util/util.module.scss' as util;
@use '~@hallmark/web.styles.colors/colors.module.scss' as colors;
@use '~@hallmark/web.styles.sizes/sizes.module.scss' as sizes;
@use '../../styles/vars.scss' as vars;

$FooterHeightMobile: 100px;

.editor {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (max-width: vars.$TabletSize) {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: calc(100% - 100px) 100px;
  }

  .workspace-panel {
    position: relative;
    height: calc(100% - $FooterHeightMobile);
    max-height: 80vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    transition: all 0.8s ease-in;
    padding-bottom: sizes.$space-32;

    @media (max-width: vars.$TabletSize) {
      height: 100%;
      padding-top: sizes.$space-16;
      padding-bottom: sizes.$space-16;

      &.is-one-to-many {
        padding-top: sizes.$space-8;
        padding-bottom: sizes.$space-40;
      }
    }
  }

  .footer,
  .footer-variant {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: $FooterHeightMobile;
    padding: sizes.$space-32;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .card-select-bar {
    grid-column: 2;
    place-self: center;
    overflow-y: hidden;
  }

  .address-button {
    grid-column: 3;
    place-self: center end;
  }

  .drawer {
    z-index: vars.$zIndexDrawer;
  }

  @media (max-width: vars.$PhoneSize) {
    .workspace-panel {
      max-height: unset;
    }
    .footer {
      display: flex;
      justify-content: space-between;
      padding: 0 sizes.$space-16 0 0;
    }

    .footer-variant {
      display: flex;
      justify-content: center;
      padding: 0 sizes.$space-16 0 0;
    }

    .address-button {
      width: 33%;
    }
  }
}
/**
 * Drawer Front Card Add Photo and Text 
 */
@media (max-width: vars.$LargerDesktop) {
  .editor.editor--drawer-front-add-text {
    left: util.rem(100px);
  }
  .editor.editor--drawer-front-add-photo {
    left: util.rem(150px);
  }
}

/**
 * Drawer Inside Card Add Photo and Text 
 */
@media (max-width: vars.$ExtraLargeDesktop) {
  .editor.editor--drawer-inside-add-text {
    left: util.rem(200px);
  }
  .editor.editor--drawer-inside-add-photo {
    left: util.rem(250px);
  }
}
