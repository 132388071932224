@use '~@hallmark/web.styles.util/util.module.scss' as util;

// DIMENSIONS
$Gutter: 25px;
$PrintOnDemandHeader: util.rem(68px);
$PrintOnDemandHeaderMobile: util.rem(54px);
$ToolBarWidth: util.rem(90px);
$ToolBarHeightMobile: util.rem(72px);
$ToolBarButtonHeight: 110px;
$DecorationMenuWidth: 700px;
//$NotificationBarHeight : "calc(2 * %s)" % Gutter;

// MEDIA QUERIES
// NarrowPhoneSize : 480px;
$PhoneSize: 560px;
$TabletSize: 767px;
$Desktop: 1023px;
$LargerDesktop: 1279px;
$ExtraLargeDesktop: 1600px;

// COLOR
$PrimaryBackground: #e4e2ed;
$SoftGrey: #8e8e8e;
$AshGrey: #4c4c4c; // common border color
$SpaceGrey: #2e2e2e; // main background accent
$SpaceGreyHighlight: #4c4c4c;
$HallMarkPurple: #562b9d; // primary accent color
$HallMarkPurpleHighlight: #2c007b;
$logoHover: #1d0055;
$OrangeLion: #ed8d38;
$CanvasGrey: #f5f5f5; // canvas color around the card
$SeaBlue: #1285be;
$GlassPlate: rgba(214, 214, 214, 0.7);
$bPostRed: #ef2637; // bpost default red
$bPostRedHighlight: #e11f2f; // bpost red highlight
$bPostLogoHover: #aa1714; // bpost logo hover
$HallmarkGradient: linear-gradient(315deg, #562b9b 0%, #ed3e6c 100%);
$InspirationItemBackground: #f0ebe7;

// DROP SHADOWS
$PaperShadow: (0px 2px 15px rgba(0, 0, 0, 0.2));
$PaperShadow2: (2px 2px 15px rgba(0, 0, 0, 0.4));
$PanelShadow: (4px 0px 10px rgba(0, 0, 0, 0.4));
$TopShadow: (0px 0px 10px rgba(0, 0, 0, 0.4));

// ANIMATION CURVES
$EaseInOutQuint: cubic-bezier(0.86, 0, 0.07, 1); // best used at transitions ranging from 0.7s to 1.5s
$EaseOutBack: cubic-bezier(0.585, 0.34, 0.275, 1.36);
$EaseInBack: cubic-bezier(0.595, -0.6, 0.22, 1);

// Z-INDEXES
// 0-9     Local stacking context
// 10+     Bars / UI
// 100+    Spinner
// 1000+   Dimmer + panels
$IndexToolBar: 10;
$IndexEditState: 11;
$IndexSpinner: 1000;
$IndexDimmer: 996;
$IndexSidePanel: 997;

// SPINNER SETTINGS
$spinnerSpeed: 3s;
$spinnerPerspective: 200px;
$spinnerSize: 100px;

// Z index
$zIndexSticky: 1020;
$zIndexDrawer: 1040;
