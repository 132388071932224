@use '~@hallmark/web.styles.util/util.module.scss' as util;
@use '~@hallmark/web.styles.sizes/sizes.module.scss' as sizes;
@use '~@hallmark/web.styles.colors/colors.module.scss' as colors;

.address-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: sizes.$space-32 sizes.$space-40;
  gap: sizes.$space-16;

  @include util.media(sizes.$mq-mobile) {
    background-color: colors.$white;
    padding: sizes.$space-12 sizes.$space-16;
    box-shadow: 0 util.rem(-2px) util.rem(10px) 0 rgba(black, 0.25);
  }

  .footer-button {
    max-width: fit-content;
    margin: 0;

    &.valid-form {
      visibility: visible;
      animation: fadeIn util.$timing-0;
    }
  }
}
