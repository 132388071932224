@use '~@hallmark/web.styles.colors/colors.module.scss' as colors;
@use '~@hallmark/web.styles.sizes/sizes.module.scss' as sizes;

.dialog-wrapper {
  .form {
    padding-top: sizes.$space-16;

    .text-field {
      width: 100%;
    }
  }

  .footer-button {
    width: fit-content;
  }
}
