.card-editor {
  /** TODO: Add new props and styles to carousel component so overrides aren't needed */
  /** Carousel Overrides */
  height: 100%;
  :global(.slider-container) {
    height: 100%;
  }
  :global(.slider-frame) {
    overflow: visible !important;
    height: 100% !important;
  }
  :global(.slider-list) {
    height: 100%;
  }
  .card-slide {
    height: 100%;
  }
}
