@use '~@hallmark/web.styles.colors/colors.module.scss' as colors;
@use '~@hallmark/web.styles.util/util.module.scss' as util;
@use '~@hallmark/web.styles.sizes/sizes.module.scss' as sizes;

$rounded-bottom: 0 0 sizes.$space-16 sizes.$space-16;

// hack to make the dialog rounded on the bottom
.recipientAddressesWrapper {
  border-radius: $rounded-bottom;
  > div > div:last-child,
  > div > div:last-child > div {
    border-radius: $rounded-bottom;
  }
}

.recipientAddresses {
  border: 1px solid colors.$light-gray;
  padding: sizes.$space-16;
  border-radius: sizes.$space-4;
}

.orderQuantity {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid colors.$light-gray;
  padding-bottom: util.rem(20px);
}

.detailRecipientsContainer {
  display: flex;
  flex-direction: row;
  padding: util.rem(20px) 0;
  border-bottom: 1px solid colors.$light-gray;
  justify-content: space-between;
  align-items: center;

  .detail {
    display: flex;
    align-items: center;

    .text {
      margin-left: sizes.$space-12;
    }
  }
}

.contact {
  padding: util.rem(10px) 0;
  border-bottom: 1px solid colors.$light-gray;
}
