@use '~@hallmark/web.styles.colors/colors.module.scss' as colors;
@use '~@hallmark/web.styles.util/util.module.scss' as util;
@use '~@hallmark/web.styles.sizes/sizes.module.scss' as sizes;

.address-confirmation-dialog {
  h2[class^='typography typography_typography-headline'] {
    font-family: 'BeamNewHMK-Bold', sans-serif;
  }

  div[class^='dialog_dialog-content'] {
    padding-bottom: sizes.$space-4 !important;
  }

  div[class^='dialog_footer'] {
    display: none;
  }

  .suggested-address-content {
    .suggested-address-description {
      margin-bottom: sizes.$space-16;

      .suggested-address-title {
        color: colors.$dark-gray;
        display: block;
        text-transform: capitalize;
      }

      .suggested-address-body {
        color: colors.$dark-gray;
        display: block;
      }
    }
  }

  .dialog-content {
    padding: 0;
  }

  .address-options {
    display: flex;
    justify-content: space-between;
  }

  .modal-actions {
    display: flex;
    padding: sizes.$space-4 0 0 0;
    justify-content: space-between;
    align-items: center;

    .address-confirmation-steps {
      min-width: fit-content;
      margin-right: util.rem(20px);
      padding: 0 util.rem(8px);
    }

    button {
      max-width: unset;
      width: 100%;
    }
  }
}
